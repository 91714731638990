.p-btn {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 100px;
    border: none;
    outline: none;
    cursor: pointer;
}

.p-sml-btn {
    padding: 6px 12px;
}

.p-sm-btn {
    padding: 12px 16px;
}

.p-md-btn {
    padding: 16px 32px;
}

.p-lg-btn {
    padding: 16px 120px;
}

.p-primary-btn {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.p-primary-trans-btn {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.p-white-trans-btn {
    background-color: transparent;
    color: var(--white-color);
    border: 1px solid var(--white-color);
}

.p-b9-trans-btn {
    background-color: transparent;
    color: var(--b9-color);
    border: 1px solid var(--b9-color);
}

.p-white-btn {
    background-color: var(--white-color);
    color: var(--b9-color);
}

.p-b9-btn {
    background-color: var(--b9-color);
    color: var(--white-color);
}

.p-b2-btn {
    background-color: var(--b2-color);
    color: var(--b9-color);
}