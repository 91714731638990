:root {
  --primary-color: #f36f3f;
  --primary-hight-color: #fef1ec;
  --white-color: #fff;
  --b9-color: #272727;
  --b7-color: #656B7F;
  --b6-color: #A1A4AC;
  --b2-color: #EAEBEB;
  --line-color: #EBEBEB;
  --boderline-color: #ECECEC;
  --green-color: #25597B;
  --negative1-color: #E83017;
  --positive1-color: #50A65C;
  --placeholder-color: #a2a2a2ab;
  --scroll-color: #B5B5B5;
  --blue-steel: #0084FF;
  --yellow: #faad07;
}

.box-shadow-card {
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.04),
}