@font-face {
  font-family: "SF Pro Display";
  src: url(./assets/fonts/SF-Pro-Display/SFProDisplay-Regular.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(./assets/fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(./assets/fonts/SF-Pro-Display/SF-Pro-Display-Light.otf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(./assets/fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf) format("truetype");
  font-weight: 700;
}

body,
html {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: #f9faff;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/* Override css */
/* body::-webkit-scrollbar {
 display: none;
} */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: var(--scroll-color);
}

.MuiPopover-paper {
  border-radius: 20px !important
}

.MuiPopover-paper .MuiList-root {
  padding: 8px !important;
}

.MuiPopover-paper .MuiList-root .MuiMenuItem-root {
  height: 45px !important;
}

.MuiPopover-paper .MuiList-root .MuiMenuItem-root:hover {
  border-radius: 12px !important;
}

.MuiPopover-paper .MuiList-root .MuiMenuItem-root:focus {
  border-radius: 12px !important;
}

.MuiPopover-paper .MuiList-root .Mui-selected {
  border-radius: 12px !important;
}